var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ac-module-heading',[_vm._v(" Staff ")]),_c('v-card',{staticClass:"card-wrapper"},[_c('v-card-text',{staticClass:"pb-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('ac-select',{staticClass:"company-select me-3",attrs:{"label":"Company","value":_vm.selectedCompany,"items":_vm.companies},on:{"change":_vm.onCompanySelect}}),(_vm.tabIndex)?_c('ac-button',{staticClass:"mr-auto",attrs:{"title":"Add Entry","icon":_vm.icons.mdiPlus},on:{"click":_vm.showEntryModal}}):_vm._e(),_c('span',{staticClass:"ml-auto"},[(!_vm.recordsExist)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ac-button',{attrs:{"title":"Download CSV","disabled":"","icon":_vm.icons.mdiDownload}})],1)]}}],null,false,2487542121)},[_c('span',{staticClass:"text-caption text-capitalize font-weight-medium"},[_vm._v(" No data availables ")])]):_c('ac-button',{attrs:{"title":"Download CSV","icon":_vm.icons.mdiDownload},on:{"click":_vm.downloadCSV}})],1)],1),_c('v-switch',{attrs:{"label":"Show Inactive Users","hide-details":""},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}})],1),_c('ac-tabs',{attrs:{"tabs":_vm.tabs},on:{"input":_vm.tabSwitch},scopedSlots:_vm._u([{key:"staff-list",fn:function(){return [_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.staffTable.headers,"items":_vm.filteredStaff,"loading":_vm.staffTable.loading,"sort-by":_vm.staffTable.sortBy},on:{"update:sortBy":function($event){return _vm.$set(_vm.staffTable, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.staffTable, "sortBy", $event)}},scopedSlots:_vm._u([{key:"item.approver",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":""},on:{"change":function($event){return _vm.updateStaffApprover(item)}},model:{value:(item.approver),callback:function ($$v) {_vm.$set(item, "approver", $$v)},expression:"item.approver"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ac-icon-btn',{attrs:{"btn-class":"me-2","text":"Remove","color":"error","loading":_vm.staffemoveInProgress(item.id),"icon":_vm.icons.mdiDelete},on:{"click":function($event){return _vm.removeStaff(item)}}})]}}],null,true)})]},proxy:true},{key:"accounts-numbers",fn:function(){return [_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.accountTable.headers,"items":_vm.filteredAccounts,"loading":_vm.accountTable.loading,"sort-by":_vm.accountTable.sortBy},on:{"update:sortBy":function($event){return _vm.$set(_vm.accountTable, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.accountTable, "sortBy", $event)}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var closingDate = ref.item.closingDate;
return [_c('span',[_vm._v(_vm._s(closingDate ? 'Closed' : 'Active'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('ac-icon-btn',{attrs:{"text":"Edit","color":"primary","btn-class":"me-1","icon":_vm.icons.mdiPencil},on:{"click":function($event){return _vm.showEditModal(item)}}}),_c('ac-icon-btn',{attrs:{"text":"Delete","color":"error","loading":_vm.accountRemoveInProgress(item.id),"icon":_vm.icons.mdiDelete},on:{"click":function($event){return _vm.removeAccount(item)}}})],1)]}}],null,true)})]},proxy:true}]),model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}})],1),_c('v-snackbar',{attrs:{"color":"info","text":"","elevation":"2","bottom":"","left":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('ac-icon-btn',_vm._b({attrs:{"btn-class":"me-2","icon":_vm.icons.mdiClose,"color":"info","no-tooltip":""},on:{"click":function($event){_vm.downloadToast = false}}},'ac-icon-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.downloadToast),callback:function ($$v) {_vm.downloadToast=$$v},expression:"downloadToast"}},[_vm._v(" Please check your downloads folder for a file named: "),_c('strong',[_vm._v(" "+_vm._s(_vm.tabIndex ? 'account-numbers.csv' : 'staff.csv')+" ")])]),_c('upsert-account-number-modal',{attrs:{"edit-item":_vm.editRecord},on:{"close":_vm.onModalClose},model:{value:(_vm.showEntry),callback:function ($$v) {_vm.showEntry=$$v},expression:"showEntry"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }