import { render, staticRenderFns } from "./Staff.vue?vue&type=template&id=15d43da0&scoped=true&"
import script from "./Staff.vue?vue&type=script&lang=js&"
export * from "./Staff.vue?vue&type=script&lang=js&"
import style0 from "./Staff.vue?vue&type=style&index=0&id=15d43da0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d43da0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCheckbox,VDataTable,VSnackbar,VSwitch,VTooltip})
