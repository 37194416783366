<template>
  <div>
    <v-file-input
      v-model="files"
      label="Upload Files"
      :prepend-icon="icons.mdiCloudUploadOutline"
      :clearable="false"
      :error-messages="errorMsg"
      dense
      counter
      outlined
      multiple
      @change="handleFileUpload"
    >
      <template #selection="{ text }">
        <v-chip small label color="primary">
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>
    <v-row v-if="selectedCompany" no-gutters>
      <v-col cols="6" v-for="(file, i) in files" :key="i" class="pb-0 pt-2 pl-2">
        <v-list-item
          :href="getUrlObj(file) && getUrlObj(file).url"
          dense
          class="elevation-1 rounded mb-1"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ file.name }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="my-2">
            <ac-icon-btn
              :loading="!loading(file) || deleteLoading(file)"
              :icon="icons.mdiDelete"
              :color="!loading(file) ? 'info' : 'error'"
              text="Remove File"
              btn-class="pa-0"
              @click="removeFile(file)"
            >
            </ac-icon-btn>
          </v-list-item-action>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AcIconBtn from '@/components/AcIconBtn.vue'
import { mdiCloudUploadOutline, mdiDelete } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AcFileUpload',
  components: {
    AcIconBtn,
  },
  data: () => ({
    icons: {
      mdiCloudUploadOutline,
      mdiDelete,
    },
    files: null,
    uploaded: [],
    urls: [],
    inProgress: { state: false, files: [] },
    errorMsg: [],
  }),
  computed: {
    ...mapGetters('app', ['selectedCompany']),

    // * URL links emitted after upload or remove
    urlLinks() {
      const urls = this.urls.map(items => items.url)
      return urls
    },
  },
  methods: {
    ...mapActions('paDealing', ['accountsUploadFile', 'accountsRemoveFile']),
    // *  upload file methods
    async handleFileUpload() {
      this.errorMsg = []
      if (this.selectedCompany) {
        await this.files.forEach(async file => {
          const payload = {
            companyID: this.selectedCompany,
            file: this.createFormData(file),
          }

          const { success, data, message } = await this.accountsUploadFile(payload)
          if (success) {
            const fileName = file.name.split(' ').join('-')
            this.urls.push({ id: fileName, url: data, key: data.split(`accountnumbers/`)[1] })
            this.uploaded.push(fileName)
            this.$emit('getLinks', this.urlLinks)
          } else {
            this.errorMsg.push(message)
          }
        })
      } else {
        this.errorMsg.push('Company ID not found !')
      }
    },
    createFormData(file) {
      const formData = new FormData()
      formData.append('file', file)
      return formData
    },
    loading(file) {
      const fileName = file?.name.split(' ').join('-')
      return this.uploaded.includes(fileName)
    },
    // * remove file methods
    async removeFile(file) {
      const fileName = file?.name.split(' ').join('-')

      this.inProgress.state = true
      this.inProgress.files.push(fileName)
      const { key } = this.urls.find(obj => {
        return obj.id === fileName
      })
      const payload = {
        items: [{ Key: key }],
      }

      const { success } = await this.accountsRemoveFile(payload)
      if (success) {
        this.files = this.files.filter(item => item !== file)
        this.urls = this.urls.filter(url => url.id !== fileName)

        this.$emit('getLinks', this.urlLinks)

        this.uploaded.filter(name => name !== fileName)
        this.inProgress.state = this.inProgress.files.filter(name => name !== fileName)
      } else {
        this.inProgress.state = false
      }
    },
    getUrlObj(file) {
      if (this.urls) {
        const fileName = file.name.split(' ').join('-')
        return this.urls.find(obj => {
          return obj.id === fileName
        })
      }
    },
    deleteLoading(file) {
      const fileName = file?.name.split(' ').join('-')
      return this.inProgress.state && this.inProgress.files.includes(fileName)
    },
    clear() {
      this.files = null
      this.uploaded = []
      this.urls = []
      this.inProgress = { state: false, files: [] }
      this.errorMsg = []
    },
  },
}
</script>

<style lang="scss" scoped></style>
