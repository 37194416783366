<template>
  <div>
    <ac-module-heading> Staff </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text class="pb-2">
        <div class="d-flex align-center">
          <ac-select
            label="Company"
            :value="selectedCompany"
            :items="companies"
            class="company-select me-3"
            @change="onCompanySelect"
          />
          <ac-button
            v-if="tabIndex"
            title="Add Entry"
            class="mr-auto"
            :icon="icons.mdiPlus"
            @click="showEntryModal"
          />
          <span class="ml-auto">
            <v-tooltip v-if="!recordsExist" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <ac-button title="Download CSV" disabled :icon="icons.mdiDownload"></ac-button
                ></span>
              </template>
              <span class="text-caption text-capitalize font-weight-medium">
                No data availables
              </span>
            </v-tooltip>

            <ac-button v-else title="Download CSV" :icon="icons.mdiDownload" @click="downloadCSV" />
          </span>
        </div>
        <v-switch v-model="showInactive" label="Show Inactive Users" hide-details></v-switch>
      </v-card-text>
      <ac-tabs v-model="tabIndex" :tabs="tabs" @input="tabSwitch">
        <template #staff-list>
          <v-data-table
            :headers="staffTable.headers"
            :items="filteredStaff"
            :loading="staffTable.loading"
            :sort-by.sync="staffTable.sortBy"
            class="text-no-wrap"
          >
            <template #[`item.approver`]="{ item }">
              <v-checkbox
                v-model="item.approver"
                hide-details
                @change="updateStaffApprover(item)"
              />
            </template>
            <template #[`item.actions`]="{ item }">
              <ac-icon-btn
                btn-class="me-2"
                text="Remove"
                color="error"
                :loading="staffemoveInProgress(item.id)"
                :icon="icons.mdiDelete"
                @click="removeStaff(item)"
              />
            </template>
          </v-data-table>
        </template>
        <template #accounts-numbers>
          <v-data-table
            :headers="accountTable.headers"
            :items="filteredAccounts"
            :loading="accountTable.loading"
            :sort-by.sync="accountTable.sortBy"
            class="text-no-wrap"
          >
            <template #[`item.status`]="{ item: { closingDate } }">
              <span>{{ closingDate ? 'Closed' : 'Active' }}</span>
            </template>

            <template #[`item.actions`]="{ item }">
              <div class="d-flex align-center justify-center">
                <ac-icon-btn
                  text="Edit"
                  color="primary"
                  btn-class="me-1"
                  :icon="icons.mdiPencil"
                  @click="showEditModal(item)"
                />
                <ac-icon-btn
                  text="Delete"
                  color="error"
                  :loading="accountRemoveInProgress(item.id)"
                  :icon="icons.mdiDelete"
                  @click="removeAccount(item)"
                />
              </div>
            </template>
          </v-data-table>
        </template>
      </ac-tabs>
    </v-card>
    <v-snackbar v-model="downloadToast" color="info" text elevation="2" bottom left>
      Please check your downloads folder for a file named:
      <strong> {{ tabIndex ? 'account-numbers.csv' : 'staff.csv' }} </strong>
      <template v-slot:action="{ attrs }">
        <ac-icon-btn
          btn-class="me-2"
          :icon="icons.mdiClose"
          color="info"
          v-bind="attrs"
          no-tooltip
          @click="downloadToast = false"
        >
          Close
        </ac-icon-btn>
      </template>
    </v-snackbar>
    <upsert-account-number-modal
      v-model="showEntry"
      :edit-item="editRecord"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcTabs from '@/components/AcTabs.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import UpsertAccountNumberModal from '@/components/PaDealing/UpsertAccountNumberModal.vue'
import { mdiDelete, mdiDownload, mdiPencil, mdiClose, mdiPlus } from '@mdi/js'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    AcButton,
    AcSelect,
    AcInput,
    AcTabs,
    AcIconBtn,
    UpsertAccountNumberModal,
  },
  data: () => ({
    icons: { mdiDelete, mdiPencil, mdiDownload, mdiClose, mdiPlus },
    tabIndex: 0,
    showInactive: false,
    downloadToast: false,
    tabs: [
      { title: 'Staff List', content: 'staff-list' },
      { title: 'Accounts Numbers', content: 'accounts-numbers' },
    ],
    staffTable: {
      headers: [
        { text: 'STAFF', value: 'name' },
        { text: 'ROLE', value: 'role' },
        { text: 'LICENSE', value: 'license' },
        { text: 'APPROVER', value: 'approver' },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      loading: false,
      sortBy: 'name',
    },
    accountTable: {
      headers: [
        { text: 'STAFF', value: 'userName' },
        { text: 'BROKER/DISCRETIONARY', value: 'broker' },
        { text: "ACCOUNT HOLDER'S NAME", value: 'holderName' },
        { text: 'ACCOUNT NUMBER', value: 'accountNumber' },
        { text: 'ACCOUNT TYPE', value: 'accountType' },
        { text: 'STATUS', value: 'status' },
        { text: 'STATEMENT REQUIRED', value: 'statementRequired' },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      loading: false,
      sortBy: 'userName',
    },
    showEntry: false,
    files: null,
    editRecord: null,
    apiInProgress: { state: false, id: [] },
  }),
  computed: {
    ...mapGetters('app', ['companies', 'selectedCompany', 'loggedInUser']),
    ...mapGetters('paDealing', ['companyStaff', 'companyStaffAccounts']),
    filteredStaff() {
      const staff = this.companyStaff || []
      return this.showInactive ? staff : staff.filter(e => e.isInActive === false)
    },
    filteredAccounts() {
      const accounts = this.companyStaffAccounts || []
      return this.showInactive ? accounts : accounts.filter(e => e.isInActive === false)
    },
    recordsExist() {
      if (!this.tabIndex) {
        return this.filteredStaff.length
      } else {
        return this.filteredAccounts.length
      }
    },
  },
  methods: {
    ...mapMutations('app', ['SET_SELECTED_COMPANY']),
    ...mapActions('paDealing', [
      'fetchCompanyStaff',
      'fetchAccounts',
      'updateApprover',
      'removeCompanyStaff',
      'accountsUploadFile',
      'removeAccountNumber',
    ]),
    ...mapMutations('paDealing', ['REMOVE_STAFF', 'REMOVE_ACCOUNT']),

    // * staff methods
    async loadCompanyStaff() {
      this.staffTable.loading = true
      await this.fetchCompanyStaff(this.selectedCompany)
      this.staffTable.loading = false
    },
    updateStaffApprover(obj) {
      const payload = {
        companyID: obj.companyID,
        userID: obj.id,
        LoggedUserId: this.loggedInUser.userId,
        editUser: {
          approverUser: obj.approver,
        },
      }
      this.updateApprover(payload)
    },
    async removeStaff(staff) {
      this.apiInProgress.state = true
      this.apiInProgress.id.push(staff.id)
      const { success, message } = await this.removeCompanyStaff(staff.id)
      if (success) {
        this.AcNotify({
          type: 'success',
          message,
          position: 'bottom',
        })
      } else {
        this.AcNotify({
          type: 'error',
          message,
          position: 'bottom',
        })
      }
      this.REMOVE_STAFF(staff.id)
      this.apiInProgress = { state: false, id: [] }
    },
    staffemoveInProgress(id) {
      return this.apiInProgress.state && this.apiInProgress.id.includes(id)
    },
    // * account-number methods
    async loadCompanyAccounts() {
      this.accountTable.loading = true
      await this.fetchAccounts(this.selectedCompany)
      this.accountTable.loading = false
    },
    showEntryModal() {
      this.showEntry = true
    },
    showEditModal(obj) {
      this.editRecord = null
      this.editRecord = obj
      this.showEntryModal()
    },
    onModalClose(refectch) {
      this.editRecord = null
      if (refectch) this.loadCompanyAccounts()
    },
    async removeAccount(account) {
      this.apiInProgress.state = true
      this.apiInProgress.id.push(account.id)
      const { success, message } = await this.removeAccountNumber(account.id)
      if (success) {
        this.AcNotify({
          type: 'success',
          message,
          position: 'bottom',
        })
      } else {
        this.AcNotify({
          type: 'error',
          message,
          position: 'bottom',
        })
      }
      this.REMOVE_ACCOUNT(account.id)
      this.apiInProgres = { state: false, id: [] }
    },
    accountRemoveInProgress(id) {
      return this.apiInProgress.state && this.apiInProgress.id.includes(id)
    },

    // * other mothods
    async onCompanySelect(id) {
      this.SET_SELECTED_COMPANY(id)
      if (!this.tabIndex) {
        await this.loadCompanyStaff()
      } else {
        await this.loadCompanyStaff() // * to update account-number modal staff list
        await this.loadCompanyAccounts()
      }
    },
    async tabSwitch(index) {
      if (!index) {
        await this.loadCompanyStaff()
      } else {
        await this.loadCompanyAccounts()
      }
    },
    downloadCSV() {
      this.downloadToast = true
      if (!this.tabIndex) {
        const sm = this.filteredStaff.map(i => [i.email || '', i.firstname || '', i.surname])
        sm.unshift(['Email', 'Firstname', 'Surname'])
        this.exportToCsv('staff.csv', sm)
      } else {
        const sm = this.filteredAccounts.map(i => [
          i.userName || '',
          i.broker || '',
          i.accountNumber || '',
          i.holderName || '',
          i.accountType || '',
        ])
        sm.unshift([
          'Staff',
          'Broker/Discretionary',
          'Account Number',
          'Account Holders Name',
          'Account Type',
        ])
        this.exportToCsv('account-numbers.csv', sm)
      }
    },
  },
  async created() {
    await this.loadCompanyStaff()
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>
